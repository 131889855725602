.NarU {
    width: 80%;
    margin: auto;
}

.NarU-Header img {
    margin-top: 8px;
    height: 256px;
    margin-right: auto;
}

.NarU-Header {
    width: 50%;
    margin: auto;
}

.NarU-Desc, .NarU-Text {
    text-align: left;
}

.NarU-Section {
    margin: 64px;
}

.NarU-Link {
    font-weight: 600;
}

.NarU-Small {
    font-size: 14px;
    font-style: italic;
    padding-bottom: 16px;
}

.NarU-List li{
    text-align: left;
    margin-bottom: 8px;
}

.NarU-Footer {
    width: 50%;
    margin: auto;
    font-weight: bolder;
    margin-bottom: 8px;
    margin: 64px;
    text-align: left;
}
/* hehe lite bus ni kan ta bort -Max */
.NarU-Footer:hover{
    color: #6dbd43;
    transform: rotate(360deg);
    transition: 1s;
}