.Contact {
    text-align: left;
}

.Contact-Name {

}

.Contact-Post {
    font-weight: 600;
}

.Contact-Mail {

}