.FAQ {
    width: 50%;
    border: 1px solid black;
    text-align: left;
    padding: 8px;
    margin: 14px;
}

.FAQ-Title {
    font-size: 16px;
    font-weight: 400;
    padding: 0;
    margin: 0;
}

.FAQ-Question, .FAQ-Answer {
    font-weight: 600;
}

.FAQ-Content {
    font-style: italic;
    padding: 0;
    margin: 0;
}