h1,
h2 {
    text-align: left;
    font-weight: normal;
}

ul,
p {
    text-align: left;
}

a {
    text-decoration: none;
    color: #215769;
}

.Middle>a:hover {
    text-decoration: underline;
}