.Footer {
    width: 100%;
    color: var(--footer-text-color);
    text-align: center;
    font-size: 14px;
}

.Footer-Content {
    background: var(--footer-bg-color);
    padding: 24px;
}

.Footer-Content a {
    text-decoration: none;
    color: var(--footer-text-color);
}

.Footer-Info>p {
    margin: 0;
    text-align: center;
}

p.Icons {
    margin-top: 1%;
}

a.Icons {
    text-decoration: none;
    color: var(--footer-text-color);
    font-size: 30px;
}

a.Icons:hover {
    color: #e5398d;
}


.Colors {
    width: 100%;
    height: 6px;
    display: flex;
}

.Color-1 {
    height: 100%;
    width: 100%;
    background-color: #754022;
}

.Color-2 {
    height: 100%;
    width: 100%;
    background-color: #E5398D;
}

.Color-3 {
    height: 100%;
    width: 100%;
    background-color: #F7E623;
}

.Color-4 {
    height: 100%;
    width: 100%;
    background-color: #70BD44;
}

.Color-5 {
    height: 100%;
    width: 100%;
    background-color: #20407C;
}

@media only screen and (min-width: 900px) {
    .narrow {
        display: none;
        width: 100%;
        padding-top: 16px;
    }
}