.Widget {
    margin: 8px;
    
    margin-left: auto;
    margin-right: auto;
}

.Widget-Title {
    text-align: center;
    font-size: 95%;
    margin: 0;
    line-height: 1.2;
    border-bottom: 1px solid var(--widget-title-border-color);
    background-color: var(--widget-title-bg-color);
    padding: 12px;
    text-transform: uppercase;
}

.Widget-Img {
    width: 128px;
    padding: 16px;
}

.fc .fc-list-empty-cushion {
    text-align: center;
}

.CalendarWidget {
    margin: 0.7em auto auto auto;
    overflow-x: hidden;
    overflow-y: scroll;
    max-width: 100%;
}

@media only screen and (max-width: 900px) {
    .Widget {
        width: 95%;
    }

    .CalendarWidget {
        max-width: 95%;
        /* margin: 0.7em auto auto auto; */
    }
}