.Valberedningen {
    width: 80%;
    margin: auto;
}

.ValleB-Desc {
    text-align: left;
}

.ValleB-Small {
    font-size: 14px;
    text-align: center;
}

.ValleB-Link-Wrapper {
    font-weight: 600;
    text-align: left;
}

.ValleB-Process-Wrapper {
    width: 75%;
    margin: auto;
    border: 1px solid black;
    text-align: left;
    padding: 16px;
    margin-bottom: 16px;
    box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
}