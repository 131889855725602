.D-Sek-Header {
    width: 100%;
    height: calc(48px + 16px);
    box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
}

.D-Sek-Header-Logo-1 {
    height: 48px;
    width: 48px;
    padding: 8px;
    margin-left: 16px;
    float: left;
}

.D-Sek-Header-Text-1 {
    margin: 0;
    font-size: 14px;
    font-style: italic;
    padding-left: 8px;
    padding-top: 24px;
    padding-bottom: 24px;
    float: left;
}

.D-Sek-Header-Logo-2 {
    height: 32px;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 8px;
    margin-right: 16px;
    float: right;
}

.D-Sek-Header-Text-2 {
    margin: 0;
    font-size: 14px;
    font-style: italic;
    padding-right: 8px;
    padding-top: 24px;
    padding-bottom: 24px;
    float: right;
}