.PubU {
    width: min(var(--uWidth), 960px);
    margin: auto;
}

.PubU p {
    text-align: center;
}

.PubU-Img {
    width: 100%;
    filter: invert();
}